import React from "react"
import Button from "../../components/button"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
// import Button from "../components/button"
import "../../styles/homestay.css"
const ProfessionalLearningDevelopment = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container" style={{marginBottom:"0px"}}>
        <div className="content">
        <div className="qualification-crumbs">
            School PLD & Gateway / Professional Learning & Development
          </div>
        <h4>Professional Learning & Development</h4>
        <p className="p-lg">In 2024-2025, our Professional Learning & Development focuses on Assessment for Learning, Structured Approaches and the use of innovative technologies such as Artificial Intelligence. These are delivered through Teacher Only Days, MOE Funded PLD, Staff Meetings, Block Courses and NZQA Micro-credentials (all delivered in person and virtually).</p>
        <p className="p-lg">Currently we are excited to be collaborating with the following schools one-off and long-term projects:</p>
        <table className="table-container">
          <tr>
            <th>Sno.</th>
            <th>Schools</th>
          </tr>
          <tr>
            <td>1.</td>
            <td>
            Ako Hiko Cluster (New Windsor School, Hay Park School, May Road School, Owairaka District School, Waikowhai Primary School, Wesley Intermediate, Wesley Primary School).
            </td>
          </tr>
          <tr>
            <td>2.</td>
            <td>
            RTLB Cluster 13 Franklin
            </td>
          </tr>
          <tr>
            <td>3.</td>
            <td>
            Manaiakalani Cliuster
            </td>
          </tr>
          <tr>
            <td>4.</td>
            <td>
            Howick College
            </td>
          </tr>
          <tr>
          <td>5.</td>
            <td>
            Papakura High School
            </td>
          </tr>
          <tr>
          <td>6.</td>
            <td>
            Buckland Beach Intermediate
            </td>
          </tr>
          <tr>
          <td>7.</td>
            <td>
            St Cuthberts College
            </td>
          </tr>
          <tr>
          <td>8.</td>
            <td>
            Takapuna Grammar
            </td>
          </tr>
          <tr>
          <td>9.</td>
            <td>
            Gelnavon school
            </td>
          </tr>
          <tr>
          <td>10.</td>
            <td>
            Bucklands Beach Primary
            </td>
          </tr>
          <tr>
          <td>11.</td>
            <td>
            Bankwood School
            </td>
          </tr>
          <tr>
          <td>12.</td>
            <td>
            Bombay School
            </td>
          </tr>
          <tr>
          <td>13.</td>
            <td>
            Mountainview School
            </td>
          </tr>
          <tr>
          <td>14.</td>
            <td>
            Pukekohe Hill School
            </td>
          </tr>
          <tr>
          <td>15.</td>
            <td>
            Pukekohe Intermediate School
            </td>
          </tr>
          <tr>
          <td>16.</td>
            <td>
            Pokeno School
            </td>
          </tr>
          <tr>
          <td>17.</td>
            <td>
            Harrisville School
            </td>
          </tr>
          <tr>
          <td>18.</td>
            <td>
            Paerata School
            </td>
          </tr>
          <tr>
          <td>19.</td>
            <td>
            Glenbrook School
            </td>
          </tr>
          <tr>
          <td>20.</td>
            <td>
            Tamaoho School
            </td>
          </tr>
          <tr>
          <td>21.</td>
            <td>
            Waiau Pa School
            </td>
          </tr>
          <tr>
          <td>22.</td>
            <td>
            Puni School
            </td>
          </tr>
          <tr>
          <td>23.</td>
            <td>
            Patumahoe School
            </td>
          </tr>
          <tr>
          <td>24.</td>
            <td>
            Pukekohe East School
            </td>
          </tr>
        </table>
        </div>
        <div style={{marginTop:"30px"}}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
        </div>
      </div>
      
    </Layout>
  )
}

export default ProfessionalLearningDevelopment
